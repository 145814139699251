
import { computed, defineComponent, onMounted, ref, watch } from "vue";
import { useRouter } from "vue-router";
import { helper } from "@/utils/helper";
import useWiseFetch from "@/composables/useWiseFetch";
import { BaseResponse } from "@/services/types";
import { isMember } from "@/utils/roles";
import { getMemberCatHistory } from "@/services/v1/member/cat";
import { getFeatureCatHistory } from "@/services/v1/feature/cat";
import { ResponseGetCatHistory } from "@/services/v1/member/cat/types";
import { DataTableHeader } from "@/global-components/data-table/types";
import { Breadcrumb, dashboardPath } from "@/components/top-bar";
import { setBreadcrumb } from "@/utils/breadcrumb";

export default defineComponent({
  setup() {
    const router = useRouter();

    // START === setup breadcrumb =====================================================
    const breadcrumb: Breadcrumb[] = [
      {
        title: "Dashboard",
        to: dashboardPath,
      },
      {
        title: "Riwayat CAT",
        to: `${dashboardPath}/riwayat-cat`,
        disabled: true,
      },
    ];

    onMounted(() => {
      setBreadcrumb(breadcrumb);
    });
    // END === setup breadcrumb =======================================================

    // START === new fetch data history cat ================================================================
    const tableHeaders = [
      {
        text: "Kode",
        value: "kode",
        itemClass: "w-36",
      },
      {
        text: "Type",
        value: "type",
      },
      {
        text: "Total Score",
        value: "totalSkor",
        headerClass: "w-12",
      },
      {
        text: "Status",
        value: "status",
        headerClass: "w-10",
      },
      {
        text: "Start Date",
        value: "startDate",
        headerClass: "w-32",
      },
      {
        text: "End Date",
        value: "endDate",
        headerClass: "w-32",
      },
      {
        text: "Action",
        value: "action",
        itemClass: "w-20",
        align: "center",
      },
    ] as DataTableHeader[];

    const page = ref(1);
    const pageSize = ref(10);

    const {
      data: dataListHistoryCat,
      pending: pendingListHistoryCat,
      error: errorListHistoryCat,
      execute: executeListHistoryCat,
    } = useWiseFetch<BaseResponse<ResponseGetCatHistory[]>>(() => {
      const params = {
        page: page.value,
        pageSize: pageSize.value,
      };

      if (isMember.value) {
        return getMemberCatHistory({ params });
      } else {
        return getFeatureCatHistory({ params });
      }
    });

    watch(dataListHistoryCat, (newVal) => {
      if (newVal) {
        console.log("dataListHistoryCat ", newVal);
      }
    });

    const totalData = computed(() => {
      const resDiagnostic = dataListHistoryCat.value?.diagnostic;
      return resDiagnostic?.totalData;
    });

    const prettierListHistoryCat = computed(() => {
      const resData = dataListHistoryCat.value?.response;
      return resData?.map((catHistory: ResponseGetCatHistory) => {
        return {
          ...catHistory,
          startDate: helper.formatDate(
            catHistory.startDate,
            "DD MMM, YYYY HH:mm:ss"
          ),
          endDate: helper.formatDate(
            catHistory.endDate,
            "DD MMM, YYYY HH:mm:ss"
          ),
          goToDetail: () => {
            router.push({
              name: "member-riwayat-cat-hasil-new",
              query: {
                catCode: catHistory.kode,
              },
            });
          },
        };
      });
    });

    onMounted(() => {
      executeListHistoryCat();
    });
    // END === new fetch data history cat ==================================================================

    return {
      tableHeaders,
      page,
      pageSize,
      prettierListHistoryCat,
      pendingListHistoryCat,
      totalData,
    };
  },
  data() {
    return {
      filter: {
        field: "skd",
      },
    };
  },
});
